import { CaseModule } from 'prosumer-case/case.module';
import { EyesSharedModule } from 'prosumer-libs/eyes-shared/eyes-shared.module';
import { ScenarioModule } from 'prosumer-scenario/scenario.module';
import { SharedModule } from 'prosumer-shared/shared.module';

import { NgModule } from '@angular/core';

import { ProjectFormComponent } from './components';
import {
  ProjectCreatePageComponent,
  ProjectDetailsPageComponent,
  ProjectListPageComponent,
} from './containers';
import { ProjectRoutingModule } from './project-routing.module';

@NgModule({
  imports: [
    EyesSharedModule.forFeature(),
    ProjectRoutingModule,
    SharedModule,
    CaseModule,
    ScenarioModule,
  ],
  declarations: [
    ProjectFormComponent,
    ProjectListPageComponent,
    ProjectCreatePageComponent,
    ProjectDetailsPageComponent,
  ],
  exports: [
    ProjectFormComponent,
    ProjectListPageComponent,
    ProjectCreatePageComponent,
    ProjectDetailsPageComponent,
  ],
})
export class ProjectModule {}
