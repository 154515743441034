import { UserInfoResolver } from 'prosumer-core/guards/user-info';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  ProjectCreatePageComponent,
  ProjectDetailsPageComponent,
  ProjectListPageComponent,
} from './containers';
import { ProjectResolverService } from './services/project-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: ProjectListPageComponent,
    resolve: {
      user: UserInfoResolver,
    },
  },
  {
    path: 'create',
    component: ProjectCreatePageComponent,
    data: {
      breadcrumb: 'Create Project',
      mode: 'create',
    },
    resolve: {
      user: UserInfoResolver,
    },
  },
  {
    path: ':projectId',
    component: ProjectDetailsPageComponent,
    data: {
      breadcrumb: 'Project',
      referenceKey: 'projectId',
      reference: true,
    },
    resolve: {
      user: UserInfoResolver,
      project: ProjectResolverService,
    },
  },
  {
    path: ':projectId/cases',
    loadChildren: () =>
      import('../+case/case.module').then((m) => m.CaseModule),
    data: {
      breadcrumb: 'Project',
      referenceKey: 'projectId',
      reference: true,
      trim: true,
      trimPath: '/cases',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule {}
