import { Case } from 'prosumer-case/models/case.model';
import { CaseFacadeService } from 'prosumer-case/state/case-facade.service';
import { ProsumerRoutePathService } from 'prosumer-core/services/prosumer-route-path/prosumer-route-path.service';
import { DarkReaderWrapper, PipeUtils } from 'prosumer-core/utils/utils';
import {
  DialogService,
  LoggerService,
  RouterStore,
  UserFacadeService,
} from 'prosumer-libs/eyes-core';
import {
  BaseComponent,
  BaseDialogComponent,
  contains,
  fadeInAnimation,
  PageMode,
  rowAnimation,
} from 'prosumer-libs/eyes-shared';
import { Project } from 'prosumer-project/models/project.model';
import { ProjectFacadeService } from 'prosumer-project/state/project-facade.service';
import { ScenarioFacadeService } from 'prosumer-scenario/state/scenario-facade.service';
import { DuplicateDialogComponent } from 'prosumer-shared/components/dialogs/duplicate-dialog/duplicate-dialog.component';
import { PermissionCheckerService } from 'prosumer-shared/services/permission-checker/permission-checker.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  mergeMapTo,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'prosumer-project-details-page',
  templateUrl: './project-details-page.component.html',
  styleUrls: ['./project-details-page.component.scss'],
  animations: [rowAnimation, fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDetailsPageComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  readonly isDarkModeEnabled = DarkReaderWrapper.isEnabled();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  formData$ = new Subject<Project>();
  mode$ = new BehaviorSubject<PageMode>('read');

  constructor(
    public routePath: ProsumerRoutePathService,
    private _route: ActivatedRoute,
    private _dialogService: DialogService,
    private _logger: LoggerService,
    private _projectFacade: ProjectFacadeService,
    private _scenarioFacade: ScenarioFacadeService,
    private _routerStore: RouterStore,
    private _userFacade: UserFacadeService,
    private _caseFacade: CaseFacadeService,
    private _permissionChecker: PermissionCheckerService,
  ) {
    super();
  }

  /* Route */
  routeMode$ = this._route.data.pipe(
    take(1),
    filter((data) => !!data),
    map((data) => data.mode || 'read'),
    this.takeUntilShare(),
  );

  routeParams$ = this._route.params.pipe(
    take(1),
    filter((params) => !!params),
    this.takeUntilShare(),
  );

  // Indicator when to fetch data
  fetchData$ = this.routeMode$.pipe(
    filter((mode) => mode === 'update' || mode === 'read'),
    this.takeUntilShare(),
  );

  /* Project */
  projectIdParam$ = this.routeParams$.pipe(
    map((params) => params.projectId),
    tap((projectId) => this._projectFacade.selectId(projectId)),
    this.takeUntilShare(),
  );

  projectMap$ = this._projectFacade.dataMap$.pipe(this.takeUntilShare());
  selectedProjectId$ = this._projectFacade.selectedId$.pipe(
    this.takeUntilShare(),
  );
  selectedProject$ = this._projectFacade.selectedData$.pipe(
    this.takeUntilShare(),
  );

  // Trigger when to fetch project data
  getProjectData$ = this.selectedProject$.pipe(
    take(1),
    filter((project) => !!!project),
    withLatestFrom(this.projectIdParam$),
    tap(([project, projectId]) => this._projectFacade.get(projectId)),
    this.takeUntilShare(),
  );

  /* Progress Indicators */
  loading$ = this.selectedProject$.pipe(
    filter((project) => !!project),
    map((project) => project.loading),
    this.takeUntilShare(),
  );

  loaded$ = this.selectedProject$.pipe(
    filter((project) => !!project),
    map((project) => project.loaded),
    this.takeUntilShare(),
  );

  updating$ = this.selectedProject$.pipe(
    filter((project) => !!project),
    map((project) => project.updating),
    this.takeUntilShare(),
  );

  /* Users */
  users$ = this._userFacade.dataMap$.pipe(this.takeUntilShare());

  /* Current User */
  currentUser$ = this._userFacade.clientUser$.pipe(this.takeUntilShare());

  owner$ = this.users$.pipe(
    filter((users) => !!users),
    mergeMap((users) =>
      this.selectedProject$.pipe(
        filter((project) => !!project),
        map((project) => (users[project.owner] || {}).fullName),
      ),
    ),
    this.takeUntilShare(),
  );

  /* Cases */
  caseLoadingList$ = this._caseFacade.loadingList$.pipe(this.takeUntilShare());

  caseLoadedList$ = this._caseFacade.loadedList$.pipe(this.takeUntilShare());

  cases$ = this.projectIdParam$.pipe(
    take(1),
    mergeMap((projectId) => this._caseFacade.filterCases$(projectId)),
    this.takeUntilShare(),
  );

  casesDataSource$ = this.cases$.pipe(
    map((cases) => {
      const datasource = new MatTableDataSource(cases);
      datasource.paginator = this.paginator;
      datasource.sort = this.sort;
      datasource.filterPredicate = (data: any, filterData: string) =>
        contains(
          data['name'].trim().toLowerCase(),
          filterData.trim().toLowerCase(),
        );
      return datasource;
    }),
    this.takeUntilShare(),
  );

  caseDataLength$ = this.casesDataSource$.pipe(
    map((dataSource) => dataSource.data.length),
    this.takeUntilShare(),
  );

  // Trigger when to fetch case list data
  getCaseList$ = this.fetchData$.pipe(
    mergeMapTo(this.selectedProjectId$),
    take(1),
    mergeMap((projectId) => this._caseFacade.getListWithDetails(projectId)),
    this.takeUntilShare(),
  );

  /* Filters */
  filter$ = new Subject<string>().pipe(
    mergeMap((filterData) =>
      this.casesDataSource$.pipe(
        map((dataSource) => (dataSource.filter = filterData)),
      ),
    ),
    this.takeUntilShare(),
  ) as Subject<string>;

  filteredDataLength$ = this.filter$.pipe(
    mergeMapTo(this.casesDataSource$),
    map((dataSource) => dataSource.filteredData.length),
    this.takeUntilShare(),
  );

  /* Access */
  canEdit$ = this.selectedProject$.pipe(
    mergeMap((project) =>
      this.projectIdParam$.pipe(
        filter(
          (projectID) =>
            !!project && project.loaded && project.id === projectID,
        ),
        mergeMap(() =>
          this.currentUser$.pipe(
            map(
              (user) =>
                this._permissionChecker.isUserPermitted(project, user)[
                  'CAN_UPDATE'
                ],
            ),
          ),
        ),
      ),
    ),
    this.takeUntilShare(),
  );

  /* Access (method) */
  hasAccess$ = (project: Project, method: 'CAN_DELETE' | 'CAN_SHARE') =>
    this.currentUser$.pipe(
      map(
        (user) =>
          this._permissionChecker.isUserPermitted(user, project)[method],
      ),
      this.takeUntilShare(),
    );

  readonly canUpdate$: Observable<boolean> = this.selectCanUpdate();

  ngOnInit() {
    this._caseFacade.selectId(undefined);
    this._scenarioFacade.selectId(undefined);
    this.filter$.subscribe();
    this.routeMode$.subscribe((mode) => this.mode$.next(mode));
  }

  ngAfterViewInit() {
    this.getProjectData$.subscribe();
    this.selectedProject$
      .pipe(
        filter(
          (projectData) =>
            !!projectData &&
            projectData.loaded &&
            !projectData.loading &&
            !projectData.updating &&
            !projectData.error,
        ),
      )
      .subscribe((project) => this.formData$.next(project));
    this.getCaseList$.subscribe();
  }

  onUpdate(project: Project) {
    this._projectFacade
      .update(project)
      .pipe(this.takeUntilShare())
      .subscribe(() => {
        this.mode$.next('read');
        this.formData$.next(project);
      });
  }

  onCancel(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.mode$.getValue() === 'update'
      ? this.mode$.next('read')
      : this._routerStore.back();
  }

  onEdit(): void {
    this.mode$.next('update');
  }

  onFilter(value: string) {
    this.filter$.next(value);
  }

  onCopy(data: Case) {
    this._dialogService
      .openDialog(DuplicateDialogComponent, {
        duplicateName: data.name,
        entityArchetype: 'case',
        width: 500,
        disableClose: true,
        existingData$: this._caseFacade.dataList$,
        case: data,
      })
      .subscribe((duplicateData) =>
        duplicateData && duplicateData.case && duplicateData.duplicateName
          ? this._caseFacade.copy(
              duplicateData.case,
              duplicateData.duplicateName,
            )
          : this._logger.debug('Duplicate cancelled'),
      );
  }

  onDelete(data: Case) {
    this._dialogService
      .openDialog(BaseDialogComponent, {
        title: 'Delete Case',
        message: `Do you wish to delete "${data.name}" case along with its
      scenarios?`,
        confirm: 'Delete',
        close: 'Cancel',
        case: data,
      })
      // .subscribe(caseData => caseData && caseData.project ?
      //   this._caseFacade.delete(caseData.case) : this._logger.debug('Delete cancelled'));
      .subscribe((dialogData) =>
        dialogData
          ? this._caseFacade.delete(dialogData.case)
          : this._logger.debug('Delete cancelled'),
      );
  }

  getCreateRouterLink(): Observable<string[]> {
    return this.canEdit$.pipe(
      map((canEdit: boolean) => {
        if (canEdit) {
          return this.routePath.createCaseRelative();
        }
        return null;
      }),
    );
  }

  private selectCanUpdate(): Observable<boolean> {
    return this.selectedProject$.pipe(
      PipeUtils.filterOutNullUndefined,
      map((project) => this.getPermissions(project)),
      map((permissions) => this.canUpdate(permissions)),
    );
  }

  private canUpdate(permissions: unknown): boolean {
    return permissions['CAN_UPDATE'];
  }

  private getPermissions(project: Project): unknown {
    return this._permissionChecker.isUserPermitted(project, null);
  }
}
