<prosumer-page>
  <prosumer-progress-card [showProgress]="creating$ | async">
    <prosumer-project-form
      [saving]="creating$ | async"
      [mode]="mode$ | async"
      (save)="onCreate($event)"
      (cancel)="onCancel()"
    >
    </prosumer-project-form>
  </prosumer-progress-card>
</prosumer-page>
